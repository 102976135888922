import React from "react";
import "./singleProcessStep.css";

const SingleProcessStep = ({ step }) => {
  return (
    <div className="single-process-step">
      <div className="process-step-image">
        <img src={step?.imageUrl} alt={step?.title} />
      </div>
      <h3 className="process-step-title">{step?.title}</h3>
      <p className="process-step-description">{step?.description}</p>
    </div>
  );
};

export default SingleProcessStep;
