import React from "react";
import "./singleSafetyFeature.css";

const SingleSafetyFeature = ({ feature }) => {
  return (
    <div className="single-safety-feature">
      <div className="safety-feature-image">
        <img src={feature?.imageUrl} alt={feature?.title} />
      </div>
      <h3 className="safety-feature-title">{feature?.title}</h3>
      <p className="safety-feature-description">{feature?.description}</p>
    </div>
  );
};

export default SingleSafetyFeature;
