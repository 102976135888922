import React from "react";
import "./featureProjects.css";
import SingleFeatureProject from "./SingleFeatureProject";
import house from "../../../assets/house.jpg";
import office from "../../../assets/office.jpg";
import bridge from "../../../assets/bridge.jpg";
const FeaturePrrojects = () => {
  const featuredProjects = [
    {
      title: "Modern Family Home",
      location: "Lahore, Pakistan",
      completed: "June 2024",
      description:
        "A contemporary two-story family home designed with an open floor plan, energy-efficient materials, and a custom interior tailored to the client's preferences.",
      imageUrl: house,
    },
    {
      title: "Corporate Office Building",
      location: "Karachi, Pakistan",
      completed: "March 2020",
      description:
        "A state-of-the-art office complex featuring advanced HVAC systems, sustainable architecture, and flexible workspaces to accommodate a growing workforce.",
      imageUrl: office,
    },
    {
      title: "Luxury Apartment Complex",
      location: "Islamabad, Pakistan",
      completed: "December 2023",
      description:
        "This upscale apartment complex includes premium amenities such as a rooftop pool, fitness center, and smart home integrations.",
      imageUrl:
        "https://images.pexels.com/photos/259950/pexels-photo-259950.jpeg",
    },
    {
      title: "Residential Renovation Project",
      location: "Faisalabad, Pakistan",
      completed: "November 2021",
      description:
        "A complete transformation of a 1970s bungalow into a modern, energy-efficient home, including new roofing, plumbing, and custom interiors.",
      imageUrl:
        "https://images.pexels.com/photos/3990359/pexels-photo-3990359.jpeg",
    },
    {
      title: "Shop Construction",
      location: "Multan, Pakistan",
      completed: "September 2022",
      description:
        "A large-scale infrastructure A compact and functional small shop designed and constructed with modern architectural principles. This project focused on maximizing limited space while ensuring durability, safety, and an aesthetic appeal to attract customers., this bridge connects key urban areas and was constructed using cutting-edge engineering techniques for durability and safety.",
      imageUrl: bridge,
    },
  ];

  return (
    <div className="feature_projects_main">
      <p className="section_title">Featured Projects</p>
      <div className="feature_projects">
        {featuredProjects?.map((item, index) => {
          return <SingleFeatureProject key={index} item={item} />;
        })}
      </div>
    </div>
  );
};

export default FeaturePrrojects;
