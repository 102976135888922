import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import "./components/common/common.css";
import News from "./components/deals/News";
import AllListings from "./components/listings/AllListings";
import ListingDetail from "./components/listing-detail/ListingDetail";
import BranchPagesList from "./components/branches/branchPages/BranchPagesList";
import About from "./components/about/About";
import AddProjectCategory from "./components/admin/project-categories/AddProjectCategory";
import AddUser from "./components/admin/users/AddUser";
import AddListing from "./components/admin/listings/AddListing";
import AddBranch from "./components/branches/AddBranch";
import { v4 as uuidv4 } from "uuid";
// admin pages
import AddProject from "./components/admin/projects/AddProject";
import AddNews from "./components/admin/news/AddNews";
import AdminLayout from "./components/admin/layout/AdminLayout";
import AdminChats from "./components/chat/AdminChats";
import Development from "./components/development/Development";
import { io } from "socket.io-client";

import useProvideGeneralHooks from "./hooks/useProvideGeneralHooks";
import { socetActions } from "./store/socketConnection";

const App = () => {
  const { dispatch } = useProvideGeneralHooks();

  useEffect(() => {
    // Create a socket connection to the server at http://localhost:5050
    const socket = io("https://portal.hafizbrothersestate.com");

    const id = localStorage.getItem("userId");
    if (!id) {
      localStorage.setItem("userId", uuidv4());
    }

    if (socket) {
      dispatch(socetActions.setConnection(socket));
      const fetchedId = localStorage.getItem("userId");
      socket.emit("register", { userId: fetchedId });
    }

    // Cleanup the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, []); // Empty dependency array to run only once on component mount

  return (
    <div className="wrapper">
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/listing/detail" element={<ListingDetail />} />
          <Route path="/listings" element={<AllListings />} />
          <Route path="/news" element={<News />} />
          <Route path="/branches" element={<BranchPagesList />} />
          <Route path="/about" element={<About />} />
          <Route path="/development" element={<Development />} />
        </Route>

        <Route element={<AdminLayout />}>
          <Route path="/project/add" element={<AddProject />} />
          <Route
            path="/project/category/add"
            element={<AddProjectCategory />}
          />
          <Route path="/user/add" element={<AddUser />} />
          <Route path="/listing/add" element={<AddListing />} />
          <Route path="/branch/add" element={<AddBranch />} />
          <Route path="/news/add" element={<AddNews />} />
          <Route path="/admin/chats" element={<AdminChats />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
