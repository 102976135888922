import React from "react";
import "./singleService.css";
const SingleService = ({ item }) => {
  return (
    <div className="single_service_main">
      <div className="service_image">
        <img src={item?.img} />
      </div>

      <div className="single_service_overlay">
        <p className="service_title">{item?.title}</p>
      </div>
    </div>
  );
};

export default SingleService;
