import React from "react";
import "./services.css";
import renovation from "../../../assets/renovation.jpeg";
import home from "../../../assets/home.jpg";
import commercial from "../../../assets/commercial.jpg";
import interior from "../../../assets/interior.webp";
import SingleService from "./SingleService";
import FeaturePrrojects from "../feature-projects/FeaturePrrojects";
import WhyUs from "../whyUs/WhyUs";
import ClientTestimonials from "../testomonials/ClientTestimonials";
import OurProcess from "../process/OurProcess";
import SafetyCompliance from "../safety/SafetyCompliance";
const Services = () => {
  const servicesData = [
    { img: renovation, title: "Renovation & Remodeling" },
    { img: home, title: "Residential Construction" },
    { img: commercial, title: "Commercial Construction" },
    { img: interior, title: "Interior Design" },
  ];

  return (
    <div className="services_main">
      <p className="section_title">Our Services</p>
      <div className="services">
        {servicesData?.map((item, index) => (
          <SingleService key={index} item={item} />
        ))}
      </div>
      <div>
        <WhyUs />
      </div>
      <div>
        <FeaturePrrojects />
      </div>
      <div>
        <ClientTestimonials />
      </div>
      <div>
        <OurProcess />
      </div>
      <div>
        <SafetyCompliance />
      </div>
    </div>
  );
};

export default Services;
