import React from "react";
import SingleProcessStep from "./SingleProcessStep";
import "./ourProcess.css";

const processSteps = [
    {
      title: "Consultation",
      description: "We begin by understanding your needs and project goals through detailed discussions.",
      imageUrl: "https://images.pexels.com/photos/3182744/pexels-photo-3182744.jpeg"
    },
    {
      title: "Design & Planning",
      description: "Our expert team creates a tailored plan and design to bring your vision to life.",
      imageUrl: "https://images.pexels.com/photos/569150/pexels-photo-569150.jpeg"
    },
    {
      title: "Construction",
      description: "We execute the plan with precision, using top-quality materials and skilled labor.",
      imageUrl: "https://images.pexels.com/photos/2760241/pexels-photo-2760241.jpeg"
    },
    {
      title: "Final Inspection",
      description: "After completion, we conduct thorough quality checks to ensure everything meets your expectations.",
      imageUrl: "https://images.pexels.com/photos/7166972/pexels-photo-7166972.jpeg"
    },
    {
      title: "Handover",
      description: "We deliver your project, ensuring you are completely satisfied with the results.",
      imageUrl: "https://images.pexels.com/photos/1068523/pexels-photo-1068523.jpeg"
    }
  ];
  

const OurProcess = () => {
  return (
    <div className="our-process">
      <h2 className="section-title">Our Process</h2>
      <div className="process-steps-container">
        {processSteps.map((step, index) => (
          <SingleProcessStep key={index} step={step} />
        ))}
      </div>
    </div>
  );
};

export default OurProcess;
