import React from "react";
import "./development.css";
import Services from "./services/Services";
const Development = () => {
  return (
    <div className="dev_main">
      <div className="dev_header">
        <div className="dev_overlay">
          <p>Building Dreams, Crafting Excellence</p>
          <p>
            Transforming visions into reality with innovative designs, quality
            craftsmanship, and a commitment to excellence. Your dream project
            starts here.
          </p>
        </div>
      </div>
      <div>
        <Services />
      </div>
    </div>
  );
};

export default Development;
