import React from "react";
import SingleClientTestimonial from "./SingleClientTestimonial";
import "./clientTestimonials.css";

const testimonials = [
  {
    name: "ali raza",
    title: "Homeowner",
    testimonial:
      "The team was incredibly professional and delivered my dream home ahead of schedule. I couldn’t be happier with the quality of their work!",
    imageUrl: "https://via.placeholder.com/150",
    location: "multan pakistan",
  },
  {
    name: "mirza tariq",
    title: "Business Owner",
    testimonial:
      "They transformed our office space into a modern and functional environment. Their attention to detail and commitment to quality were outstanding.",
    imageUrl: "https://via.placeholder.com/150",
    location: "islamabad pakistan",
  },
  {
    name: "basheer ahmad",
    title: "Investor",
    testimonial:
      "I’ve worked with many construction teams, but this company stands out for their transparency and dedication. Highly recommended!",
    imageUrl: "https://via.placeholder.com/150",
    location: "lahore pakistan",
  },
];

const ClientTestimonials = () => {
  return (
    <div className="client-testimonials">
      <h2 className="section-title">What Our Clients Say</h2>
      <div className="testimonials-container">
        {testimonials.map((testimonial, index) => (
          <SingleClientTestimonial key={index} item={testimonial} />
        ))}
      </div>
    </div>
  );
};

export default ClientTestimonials;
