import React from "react";
import "./singleFeatrureProject.css";

const SingleFeatureProject = ({ item }) => {
  return (
    <div className="single-feature-project">
      <div className="feature-project-image">
        <img src={item?.imageUrl} alt={item?.title} />
      </div>
      <div className="feature-project-content">
        <h2 className="feature-project-title">{item?.title}</h2>
        <div className="feature-project-meta">
          <span>
            <strong>Location:</strong> {item?.location}
          </span>
          <span>
            <strong>Completion Date:</strong>
            {new Date(item?.completed).toLocaleDateString()}
          </span>
        </div>
        <p className="feature-project-description">{item?.description}</p>
        
          
          <button className="feature-project-button">View More Details</button>
    
      </div>
    </div>
  );
};

export default SingleFeatureProject;
