import React from "react";
import SingleSafetyFeature from "./SingleSafetyFeature";
import "./safetyCompliance.css";

const safetyFeatures = [
  {
    title: "Certified Work Practices",
    description: "All projects comply with local and international building codes, ensuring safety and legal compliance.",
    imageUrl: "https://images.pexels.com/photos/8961144/pexels-photo-8961144.jpeg"
  },
  {
    title: "Workforce Safety",
    description: "Our workers are trained in the latest safety protocols to minimize risks on-site.",
    imageUrl: "https://images.pexels.com/photos/38293/construction-site-building-site-building-38293.jpeg"
  },
  {
    title: "High-Quality Materials",
    description: "We use only premium materials that meet safety and durability standards.",
    imageUrl: "https://images.pexels.com/photos/209719/pexels-photo-209719.jpeg"
  },
  {
    title: "Regular Inspections",
    description: "We conduct regular safety audits and quality inspections to maintain compliance throughout the project lifecycle.",
    imageUrl: "https://images.pexels.com/photos/930383/pexels-photo-930383.jpeg"
  },
  {
    title: "Licensed & Insured",
    description: "We are fully licensed and insured, giving you peace of mind while your project is underway.",
    imageUrl: "https://images.pexels.com/photos/1020315/pexels-photo-1020315.jpeg"
  }
];

const SafetyCompliance = () => {
  return (
    <div className="safety-compliance">
      <h2 className="section-title">Safety & Compliance</h2>
      <div className="safety-features-container">
        {safetyFeatures.map((feature, index) => (
          <SingleSafetyFeature key={index} feature={feature} />
        ))}
      </div>
    </div>
  );
};

export default SafetyCompliance;
