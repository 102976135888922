import React from "react";
import "./whyUs.css";
import whyUs from "../../../assets/why us.avif";
const WhyUs = () => {
  const whyChooseUs = [
    {
      title: "Experienced Professionals",
      detail:
        "Our team comprises seasoned experts with over 20 years in the construction industry, ensuring top-notch quality and reliability.",
    },
    {
      title: "Client-Centric Approach",
      detail:
        "We prioritize your vision, working closely with you to bring your dream project to life, on time and within budget.",
    },
    {
      title: "Quality Assurance",
      detail:
        "Utilizing premium materials and adhering to stringent safety standards, we deliver durable and sustainable constructions.",
    },
    {
      title: "Transparent Communication",
      detail:
        "From project inception to completion, we maintain open and honest communication, keeping you informed at every stage.",
    },
    {
      title: "Comprehensive Services",
      detail:
        "Offering a wide range of services from residential builds to commercial projects, we are your one-stop solution for all construction needs.",
    },
  ];

  return (
    <div className="why_us_main">
      <div className="why_us_image">
        <img src={whyUs} />
      </div>
      <div className="why_us_content">
        <span className="content_title">
          Why Choose Us for Your Construction Needs?
        </span>
        <div className="cntent_items">
          {whyChooseUs?.map((item, index) => {
            return (
              <div className="content_item" key={index}>
                <span className="why_us_cntent_title" >{item?.title}</span>
                <span className="why_us_content_detail" >{item?.detail}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
