import React from "react";
import "./singleClientTestimonial.css";

const SingleClientTestimonial = ({ item }) => {
  return (
    <div className="single-client-testimonial">
      {/* <div className="client-image">
        <img src={item?.imageUrl} alt={item?.name} />
      </div> */}
      <div className="client-info">
        <h3 className="client-name">{item?.name}</h3>
        <span className="client-title">{item?.title}</span>
        <p className="client-location">{item?.location}</p>
        <p className="client-testimonial">"{item?.testimonial}"</p>
      </div>
    </div>
  );
};

export default SingleClientTestimonial;
